import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { PageRow, PageExpand } from '../global'

import PreviewList from '../projects/preview-list'

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          projects: allProjectsJson(
            sort: { fields: [completion], order: DESC }
            filter: { isComplete: { eq: true } }
          ) {
            edges {
              node {
                id
                project
                suburb
                feature {
                  src
                  width
                }
              }
            }
          }
          images: allFile(
            filter: { absolutePath: { regex: "/.images/progress|complete./" } }
          ) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 400, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const rows = (
          <PreviewList projects={data.projects} images={data.images} />
        )
        return (
          <PageRow>
            <PageExpand title="Complete" content={rows} isOpen={true} />
          </PageRow>
        )
      }}
    />
  )
}
