import { graphql } from 'gatsby'
import React from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import { PageWrap, PageCopy, PageRow, PageHead } from '../components/global'
import Complete from '../components/home/complete'
import Progress from '../components/home/progress'
import SEO from '../components/seo'
import PreviewItem from '../components/projects/preview-item'
import { addImageSharpData } from '../util/helpers'

import HomeFeatureData from '../data/home-feature.json'

export default ({ data }) => {
  const allImages = data.images

  const imagesWithSharp = addImageSharpData(HomeFeatureData, allImages)

  return (
    <PageWrap>
      <SEO title="" />
      <PageRow>
        <PageHead>
          Detailed Building
          <br />
          <span>Crafting Homes</span>
        </PageHead>
        <Flex flexDirection="column">
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            width={[1, 4 / 8]}
            px={[2, 3]}
            pt={[3, 4]}
            pb={[0, 0]}
          >
            <PageCopy>
              <p>
                As custom build specialists we work alongside architects and
                designers with an approach that continues to earn us a
                reputation for diligence in every detail.
              </p>
            </PageCopy>
          </Box>
          <Box width={[1]} px={[0]} pb={[3, 5]}>
            <PreviewItem images={imagesWithSharp} />
          </Box>
        </Flex>
      </PageRow>

      <PageRow>
        <PageHead hideBorder={true}>Selected Work</PageHead>
      </PageRow>
      <Complete />
      <Progress />
    </PageWrap>
  )
}

export const query = graphql`
  query {
    images: allFile(
      filter: { absolutePath: { regex: "/.images/home|complete./" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
