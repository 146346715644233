import React from 'react'
import { addImageSharpData } from '../../util/helpers'
import PreviewItem from './preview-item'

export default ({ projects, images }) => {
  return projects.edges.map((edge, rowIndex) => {
    const project = edge.node
    const isEven = rowIndex % 2 === 1

    // add sharp data
    const imagesWithSharp = addImageSharpData(project.feature, images)

    // swap order alternating
    if (imagesWithSharp.length >= 2) {
      if (isEven) {
        imagesWithSharp.push(imagesWithSharp[0])
        imagesWithSharp.shift()
      }
    }

    return (
      <PreviewItem
        key={`row-${rowIndex}`}
        url={`/${project.id}`}
        images={imagesWithSharp}
        caption={`${project.project} ${project.suburb}`}
      />
    )
  })
}
